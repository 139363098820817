import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Box, Typography } from '@mui/material'
import { RenewalAPI } from 'Services/RenewalAPIs';
import { isBasicItemCode, productsState } from 'Stores/Products/ProductStore';
import { cartState } from 'Stores/Cart/CartInterface';
import { isSizeWindowState } from 'Stores/SizeWindow/SizeWindowStore';
import { languageState } from 'Stores/Dictionaries/DictionariesStore';
import { guideState } from 'Stores/Guide/GuideStore';
import { useTranslate } from 'Stores/Dictionaries/useDictionaries';
import { Product } from 'Constants/Interface/Product';
import { ReactComponent as CheckCircleIcon } from '../../../Assets/icons/check-circle-icon.svg'
import CustomButton from '../CustomButton/CustomButton';
import { styles } from './LandingPage.style'
import PopupLandingGuide from '../UrenewGuide/PopupLandingGuide';
import '../UrenewGuide/style.css'

const RenewalProducts = () => {
  const products = useRecoilValue(productsState);
  const [cartItem, setCartItem] = useRecoilState(cartState)
  const [language, setLanguage] = useRecoilState(languageState);
  const sizeWindow = useRecoilValue(isSizeWindowState)
  const basicItemCode = useRecoilValue(isBasicItemCode)
  const [guide, setGuide] = useRecoilState(guideState)

  const [loadingBasic, setLoadingBasic] = useState(false)
  const [loadingRenewal, setLoadingRenewal] = useState(false)
  const [loadingPack, setLoadingPack] = useState(false)
  const [disabledBasic, setDisabledBasic] = useState(false)
  const [disabledPack, setDisabledPack] = useState(false)

  const [translate] = useTranslate()
  const navigate = useNavigate();
  const params = useLocation()

  const renewalPackSuggestion = products?.renewal.find(x => x.item_code === '35413') || products?.renewal[2]
  const renewalBasic = products?.renewal.find(x => x.item_code === basicItemCode) || products?.renewal[0]

  const keyLanguage = ['en', 'ja', 'jp']

  useEffect(() => {
    if (params.search) {
      const langParams = params.search.replace('?lang=', '').toLowerCase()
      if (keyLanguage.includes(langParams)) {
        setLanguage(langParams === 'jp' ? 'ja' : langParams)
      } else {
        setLanguage('en')
      }
    }

    const sessionGuide: any = sessionStorage.getItem('isGuide')
    setGuide(JSON.parse(sessionGuide))
  }, [])

  const matchLanguage = (lang: { english: string, native: string }) => {
    const l = language === 'en' ? lang.english : lang.native
    return l
  }

  const formatNumberWithCommas = (value: any) => {
    const data = new Intl.NumberFormat().format(value)
    return data
  };

  const handleRenewalBasic = (product: Product) => {
    console.log('product', product)
    if (product.item_code === basicItemCode) {
      setLoadingBasic(true)
    } else if (product.item_code === '32120') {
      setLoadingRenewal(true)
    }
    setDisabledBasic(true)

    const itemInCart = {
      product,
      quantity: 1
    }
    setCartItem([itemInCart])

    setTimeout(() => {
      if (product.item_code === basicItemCode) {
        setLoadingBasic(false)
      } else if (product.item_code === '32120') {
        setLoadingRenewal(false)
      }
      setDisabledBasic(false)
      navigate('orderDetail')
    }, 1500)
  }

  const handleRenewalPack = (product: Product) => {
    setLoadingPack(true)
    setDisabledPack(true)

    const itemInCart = {
      product,
      quantity: 1
    }
    setCartItem([itemInCart])

    
    setTimeout(() => {
      setLoadingPack(false)
      setDisabledPack(false)
      navigate('orderDetail')
    }, 1500)
  }

  const handleClickDoneGuide = async () => {
    try {
      const baId = sessionStorage.getItem('baId')
      const newGuide = {
        ...guide,
        isLandingPageGuide: false
      }
      const res = await RenewalAPI.postIsShowGuide(baId as string, newGuide)
      if (res) {
        sessionStorage.setItem('isGuide', JSON.stringify(newGuide))
        setGuide(newGuide);
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Stack>
      {guide.isLandingPageGuide && <Box sx={styles.backDrop}></Box>}

      <Box sx={styles.wrapperHeader}>
        <Typography variant='header' color='#fff'>
          {translate('landing_page_header')}
        </Typography>
      </Box>

      <Box sx={styles.wrapperContent} >
        <Stack sx={{
          gap: sizeWindow > 1366 ? '20px' : '15px',
          maxWidth: sizeWindow > 1366 ? '1286px' : '100%',
          margin: sizeWindow > 1366 ? '0 auto' : '',
          position: 'relative'
        }}>

          {/* start renewal pack */}
          {renewalPackSuggestion && (
            <Stack sx={styles.renewalPackWrapper} style={{ opacity: (loadingPack ? '0.5' : '1') }}>
              <Box className={guide.isLandingPageGuide ? 'ribbon-backdrop' : ''} sx={styles.ribbon}>
                <Typography
                  variant='title2'
                  sx={{
                    color: '#ffffff',
                    '@media (max-width: 600px)': {
                      fontSize: '14px'
                    }
                  }}>
                  {translate('urenew_recommend')}
                </Typography>
              </Box>

              <Box sx={{
                width: '100%',
                '@media (min-width: 820px)': {
                  width: '45%',
                },

                '@media (min-width: 1366px)': {
                  width: '40%',
                }
              }}>
                <Box sx={styles.renewalPackImage}>
                  <img src={renewalPackSuggestion?.image_url} alt='renewalPack_image' />
                </Box>
              </Box>

              <Stack className={`${guide.isLandingPageGuide ? 'renewalPackDetailWrapper-drop' : ''}`} sx={styles.renewalPackDetailWrapper}>
                <Box sx={{
                  width: '100%',
                  marginBottom: '20px',
                  '@media (min-width: 820px)': {
                    width: '80%',
                  }
                }}>
                  <Typography variant='title'>
                    {matchLanguage(renewalPackSuggestion?.item_name ? renewalPackSuggestion?.item_name : { english: '', native: '' })}
                  </Typography>
                </Box>

                <Typography
                  variant='label'
                  sx={{
                    marginBottom: '2px',
                    '@media (min-width: 820px)': {
                      fontSize: '24px',
                    }
                  }}
                >
                  {formatNumberWithCommas(renewalPackSuggestion?.pv)} {translate('cart_pv')} | {formatNumberWithCommas(renewalPackSuggestion?.price)} {translate('currency_JPY')}
                </Typography>

                <Typography variant='caption' color={'#33628C'} sx={{ marginBottom: '15px' }}>
                  {translate('urenew_code')}: {renewalPackSuggestion?.item_code}
                </Typography>

                <Typography variant='caption' sx={{ marginBottom: '15px' }}>
                  {translate('renewal_pack_detail')}
                </Typography>

                <Box sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  marginBottom: '15px',
                  '@media (min-width: 1366px)': {
                    marginBottom: '10px'
                  }
                }}>
                  <CheckCircleIcon />

                  <Typography variant='caption'>
                    {translate('renewal_fee_waived')}
                  </Typography>
                </Box>

                <Stack sx={{
                  '@media (min-width: 820px)': {
                    flexDirection: 'row',
                    justifyContent: 'end',
                    alignItems: 'center'
                  }
                }}>
                  <Box sx={{
                    width: '100%',
                    '@media (min-width: 820px)': {
                      width: '310px'
                    },

                    '@media (min-width: 1024px)': {
                      width: '350px'
                    },
                    
                    '@media (min-width: 1366px)': {
                      width: '375px'
                    }
                  }}>
                    <CustomButton
                      title={translate('get_renewal_pack')}
                      bgColor='#003B6F'
                      fontSize='18px'
                      isColorDisabled='#003B6F'
                      isOpacity={loadingPack ? 0.5 : 1}
                      loading={loadingPack}
                      disabled={disabledPack}
                      onClick={() => handleRenewalPack(renewalPackSuggestion)}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          )}

          {guide.isLandingPageGuide && <PopupLandingGuide onClickDoneGuide={handleClickDoneGuide} />}


          {/* start basic renewal 0100 */}
          <Stack sx={styles.renewalBasicWrapper}>
            <Box sx={styles.renewalBasicNameBox}>
              <Typography variant='label2'>
                {matchLanguage(renewalBasic?.item_name)}
              </Typography>
            </Box>

            <Stack sx={styles.renewalBasicDetailWrapper}>
              <Stack sx={{
                gap: '25px',
                '@media (min-width: 820px)': {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row'
                }
              }}>

                <Stack>
                  <Typography
                    variant='label'
                    sx={{
                      marginBottom: '2px',
                      textAlign: 'left'
                    }}
                  >
                    {formatNumberWithCommas(renewalBasic?.price)} {translate('currency_JPY')}
                  </Typography>

                  <Typography variant='caption' color={'#33628C'}>
                    {translate('urenew_code')}: {renewalBasic?.item_code}
                  </Typography>
                </Stack>

                <Box sx={{
                  width: '100%',
                  '@media (min-width: 820px)': {
                    width: '310px'
                  },

                  '@media (min-width: 1024px)': {
                    width: '350px'
                  },

                  '@media (min-width: 1366px)': {
                    width: '375px'
                  }
                }}>
                  <CustomButton
                    title={translate('get_basic_only')}
                    bgColor='#F0F2F5'
                    fontSize='18px'
                    isColorDisabled='#F0F2F5'
                    border='2px solid #003B6F'
                    color='#003B6F'
                    isOpacity={loadingBasic ? 0.5 : 1}
                    loading={loadingBasic}
                    disabled={disabledBasic}
                    onClick={() => handleRenewalBasic(renewalBasic)}
                  />
                </Box>

              </Stack>
            </Stack>
          </Stack>

          {/* end basic renewal */}
        </Stack>
      </Box >
    </Stack >
  )
}

export default RenewalProducts